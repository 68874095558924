import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

//
// IntersectionObserver polyfill
// --------------------------------------------------
import 'intersection-observer';

//
// Code splitting
// --------------------------------------------------
import loadable from '@loadable/component';

//
// Styles
// --------------------------------------------------
import './Styles/style.scss';

//
// Main
// --------------------------------------------------
function App() {
	const Home = loadable(() => import('./Pages/Home'));
	const Imprint = loadable(() => import('./Pages/Imprint'));
	const Privacy = loadable(() => import('./Pages/Privacy'));
	const FourZeroFour = loadable(() => import('./Pages/FourZeroFour'));

	return (
		<Router>
			<Switch>
				<Route exact path={['/']} component={Home} />
				<Route exact path={['/imprint']} component={Imprint} />
				<Route exact path={['/privacy']} component={Privacy} />
				<Route component={FourZeroFour} />
			</Switch>
		</Router>
	);
}

export default App;
